<template>
  <div>
    <div id="wemap-header"></div>
    <router-view />
    <div id="wemap-footer"></div>
  </div>
</template>
<script>
export default {
  name: 'simpleFooterLayout',
  mounted() {
    window.TWeMapHeader.init({
      container: document.getElementById('wemap-header'),
      theme: 'dark-transparent',
    });
    window.TWeMapFooter.init({
      container: document.getElementById('wemap-footer'),
      type: 'simple'
    });
  },
  methods: {
  },
};
</script>
<style lang="less">
@import '@/styles/index.less';
</style>
